import { Fragment, useEffect } from 'react'
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid'

const statuses = {
  Paid: 'text-green-700 bg-green-50 ring-green-600/20',
  Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
}
const days = [
  {
    date: 'Today',
    dateTime: '2023-03-22',
    transactions: [
      {
        id: 1,
        invoiceNumber: '00012',
        href: '#',
        amount: '$7,600.00 USD',
        tax: '$500.00',
        status: 'Paid',
        client: 'Reform',
        description: 'Website redesign',
        icon: ArrowUpCircleIcon,
      },
      {
        id: 2,
        invoiceNumber: '00011',
        href: '#',
        amount: '$10,000.00 USD',
        status: 'Withdraw',
        client: 'Tom Cook',
        description: 'Salary',
        icon: ArrowDownCircleIcon,
      },
      {
        id: 3,
        invoiceNumber: '00009',
        href: '#',
        amount: '$2,000.00 USD',
        tax: '$130.00',
        status: 'Overdue',
        client: 'Tuple',
        description: 'Logo design',
        icon: ArrowPathIcon,
      },
    ],
  },
  {
    date: 'Yesterday',
    dateTime: '2023-03-21',
    transactions: [
      {
        id: 4,
        invoiceNumber: '00010',
        href: '#',
        amount: '$14,000.00 USD',
        tax: '$900.00',
        status: 'Paid',
        client: 'SavvyCal',
        description: 'Website redesign',
        icon: ArrowUpCircleIcon,
      },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tableview({products, event, isLoading, retry}) {
    // console.log(products)

    function truncate(source, size) {
        return source.length > size ? source.slice(0, size - 1) + "…" : source;
    }

    //This function still not work
    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center space-y-4">
                <p>Loading products...</p>
                <button onClick={retry} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Retry
                </button>
            </div>
        );
    } else 
        return (
            <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h2 className="mx-auto max-w-2xl text-base font-semibold text-gray-900 lg:mx-0 lg:max-w-none">
                Recent Projects
                </h2>
            </div>
            <div className="mt-6 overflow-hidden border-t border-gray-100">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <table className="w-full text-left">
                    <thead className="sr-only">
                        <tr>
                        <th>Amount</th>
                        <th className="hidden sm:table-cell">Client</th>
                        <th>More details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product) => (
                            <tr key={product.id}>
                                <td className="relative py-5 pr-6">
                                    <div className="flex gap-x-6">
                                        {/* <tra.icon
                                            aria-hidden="true"
                                            className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                        /> */}
                                        <div className="flex-auto">
                                            <div className="flex items-start gap-x-3">
                                                <div className="text-sm/6 font-medium text-gray-900">{truncate(product.name, 20)}</div>
                                                {/* <div className="text-xs/5 text-gray-500">{product.status}</div> */}
                                                {/* <div
                                                    className={classNames(
                                                        statuses[product.status],
                                                        'rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
                                                    )}
                                                >
                                                    {product.status}
                                                </div> */}
                                            </div>
                                            <div className="mt-1 text-xs/5 text-gray-500">{product.location}</div>
                                            <div className="mt-1 text-xs/5 text-gray-500">{product.dateProjectInit}</div>
                                            {/* {product.tax ? (
                                                <div className="mt-1 text-xs/5 text-gray-500">{product.tax} tax</div>
                                            ) : null} */}
                                        </div>
                                        <button onClick={() => event(product.qrCode, product.name)}>
                                            <img src={product.qrCode} alt="qr code" className='w-[50px]'/>  
                                        </button>
                                    </div>
                                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                </td>
                                <td className="hidden py-5 pr-6 sm:table-cell">
                                    <div className="text-sm/6 text-gray-900">ID: {product.productId}</div>
                                    <div className="mt-1 text-xs/5 text-gray-500">{product.description}</div>
                                    <div className="mt-1 text-xs/5 text-gray-500">{product.status}</div>
                                </td>
                                <td className="py-5 text-right">
                                    <div className="flex justify-end">
                                        <a
                                            href={product.receipt}
                                            className="text-sm/6 font-medium text-indigo-600 hover:text-indigo-500"
                                        >
                                            View<span className="hidden sm:inline"> transaction</span>
                                            <span className="sr-only">
                                                , invoice #{product.receipt}, {product.farmId}
                                            </span>
                                        </a>
                                    </div>
                                    <div className="mt-1 text-xs/5 text-gray-500">
                                        Product link: <a target='_blank' href={product.productUrl} className="text-gray-900">{product.productUrl}</a>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        )
    


}
