export default function CardView ({products, event}) {
    return(
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
            {products.map((product) => (
                <div
                    key={product.id}
                    className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                >
                    <img
                        // alt={product.imageAlt}
                        src={product.qrCode}
                        className="aspect-[3/4] w-full bg-gray-200 object-cover group-hover:opacity-75 sm:aspect-auto sm:h-96"
                    />
                    <div className="flex flex-1 flex-col space-y-2 p-4">
                        <h3 className="text-sm font-medium text-gray-900">
                            <a href={product.receipt}>
                                {/* <span aria-hidden="true" className="absolute inset-0" /> */}
                                {product.name}
                            </a>
                        </h3>
                        <p className="text-sm text-gray-500">{product.location}</p>
                        <div className="flex flex-1 flex-col justify-end">
                            <p className="text-sm italic text-gray-500">{product.farmId}</p>
                            <p className="text-base font-medium text-gray-900">{product.message}</p>
                        </div>
                        <button onClick={() => event(product.qrCode, product.name)}>Download QR Code</button>
                    </div>
                </div>
            ))}
        </div>
    )
}