import React, {Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import Form from './components/form';
import Header from './components/header';
import Tableview from './components/tableview';
import CardView from './components/cardview';

function Dashboard() {
  const [products, setProducts] = useState([]);
  console.log("Products: ", products)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null)
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
      // Function to determine if the screen is considered 'mobile'
      const checkIsMobile = () => window.innerWidth <= 768; // Adjust the breakpoint as needed

      // Set initial state
      setIsMobile(checkIsMobile());

      const handleResize = () => {
          setIsMobile(checkIsMobile());
      };

      // Add event listener for window resize
      window.addEventListener('resize', handleResize);

      // Cleanup: Remove event listener on unmount
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchProducts = async () => {
    try {
        // const response = await fetch('https://linitani-backend-dev-5ecf7c5e0bda.herokuapp.com/api/products');
        const response = await fetch('http://localhost:3005/api/products');
        const data = await response.json();   

        setProducts(data);
    } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([
            {
              productId: '',
              name: '',
              farmId: '',
              location: '',
              description: '',
              success: false,
              receipt: '',
              message: '',
              productUrl: '',
              qrCode: '',
              qrCodeFilePath: '',
              productName: '',
              currentOwner: '',
              status: ''
            },
        ]);
        alert("Failed to fetch products. Please try again later."); 
    }
  };

  const fetchProductsLoad = async () => {
        
    setIsLoading(true);
    try {
      fetchProducts();
    } catch (error) {
      console.error("Error fetching products:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    fetchProductsLoad()
  }, []);

  function downloadQRCode(qrCodeData, productName) {
    const link = document.createElement('a');
    link.href = qrCodeData;
    link.download = `qrcode-${productName.replace(/ /g, "_")}.png`; // Use product name for filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="bg-white">
      <Header/>
      <button
          type='button'
          onClick={() => setIsModalOpen(true)}
          className="md:hidden w-[90vw] ml-4 mt-4 justify-center rounded-md bg-[#2b8445] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#9adeae] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2b8445]"
      >
          New Project
      </button>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Products</h2>
          {/* <CardView products={products} event={downloadQRCode}/> */}
          {isMobile ? (
            <CardView products={products} event={downloadQRCode} isLoading={isLoading}/>
          ) : (
            <Tableview products={products} event={downloadQRCode} isLoading={isLoading} retry={() => { setError(null); fetchProductsLoad(); }}/>
          )}
      </div>
       <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => setIsModalOpen(false)}>
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"   

                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                      >
                          <Dialog.Panel className="w-full max-w-full md:max-w-[50vw] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                              <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"   

                              >
                                  New Project
                              </Dialog.Title>
                              <div className="mt-2">   

                                  {/* Your form component goes here */}
                                  <Form onClose={setIsModalOpen} onProductCreated />
                              </div>
                          </Dialog.Panel>
                      </Transition.Child>
                  </div>
              </div>
          </Dialog>
      </Transition>
  </div>
  );
}

export default Dashboard;
