'use client'

import { Fragment, useState, useEffect } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Helmet } from 'react-helmet'

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'About Us', href: '#' },
  { name: 'Our Solution', href: '#' },
  { name: 'Our Impact', href: '#' },
]
const features = [
  {
    name: 'Push to deploy',
    description:
      'Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi. Odio urna massa nunc massa.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'SSL certificates',
    description:
      'Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget. Sem sodales gravida quam turpis enim lacus amet.',
    icon: LockClosedIcon,
  },
  {
    name: 'Simple queues',
    description:
      'Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced security',
    description:
      'Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.',
    icon: FingerPrintIcon,
  },
]

const contents = [
    {
        tags: "Building People",
        title: "Monitoring, Maintenance and Educating",
        description: "Our number one service is working closer with our farmers partners. We give an advice, monitored every week, give them access on our clinic to consult their obstacle and accompanying them in every condition.",
        img: "/wedo-image1.png",
        reverse: false // false means the image is on the left side
    },
    {
        tags: "Unlocking The Market",
        title: "Breaking a Potential Market to Give a Good Prospect",
        description: "We’re not only focus on farm side but we also help our partners to get stability or extra income with quality and prosperity from their products",
        img: "/wedo-image2.png",
        reverse: true // true means the image is on the right side
    },
    {
        tags: "Building Technology",
        title: "Prevention Risk and Forecasting Production with Single System",
        description: "Realizing that agriculture things have a big issue on risk management, traceability and trust issue on buyer side. So we build the system who can trace every activity on-farm to off-farm with blockchain technology. This can be more traceable, trusted and transparent for everyone",
        img: "/wedo-image3.png",
        reverse: false // false means the image is on the left side
    }
]
const tiers = [
  {
    name: 'Freelancer',
    id: 'tier-freelancer',
    href: '#',
    priceMonthly: '$19',
    description: 'The essentials to provide your best work for clients.',
    features: ['5 products', 'Up to 1,000 subscribers', 'Basic analytics', '48-hour support response time'],
    mostPopular: false,
  },
  {
    name: 'Startup',
    id: 'tier-startup',
    href: '#',
    priceMonthly: '$49',
    description: 'A plan that scales with your rapidly growing business.',
    features: [
      '25 products',
      'Up to 10,000 subscribers',
      'Advanced analytics',
      '24-hour support response time',
      'Marketing automations',
    ],
    mostPopular: true,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: '$99',
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      '1-hour, dedicated support response time',
      'Marketing automations',
    ],
    mostPopular: false,
  },
]
const faqs = [
  {
    id: 1,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
]
const footerNavigation = {
  solutions: [
    { name: 'Hosting', href: '#' },
    { name: 'Data services', href: '#' },
    { name: 'Uptime monitoring', href: '#' },
    { name: 'Enterprise services', href: '#' },
    { name: 'Analytics', href: '#' },
  ],
  support: [
    { name: 'Submit ticket', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
  ],
  legal: [
    { name: 'Terms of service', href: '#' },
    { name: 'Privacy policy', href: '#' },
    { name: 'License', href: '#' },
  ],
}

const stats = [
    { id: 1, name: 'Farmers Patrners', value: '100+' },
    { id: 2, name: 'Coverage Aret', value: '10 h.a.' },
    { id: 3, name: 'Comodities Focus', value: '1 (Potatoes)' },
  ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FrontPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [imgWidth, setImgWidth] = useState(0);
  useEffect(() => {
    const img = new Image();
    img.src = "/image-section2-1.png";

    img.onload = () => {
      setImgWidth(img.naturalWidth);
    };
  }, []);

  return (
    <Fragment>
        <Helmet>
            <title>Linitani - Sustainability & Traceability Agriculture</title>
            <meta
                name="description"
                content="Enhance Sustainability & Traceability Agriculture with Blockchain Environment"
            />
            <meta
                property="og:image"
                content="/hero.png" 
            />
            <meta
                name="twitter:image"
                content="/hero.png" 
            />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="author" content="Linitani Dev" />
            <meta name="keywords" content="Agriculture, blockchain, Supply chain" />
        </Helmet>
        <div className="bg-[#0d1429]">
            {/* Header */}
            <header className="absolute inset-x-0 top-0 z-50">
                <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Linitani</span>
                    <img
                        alt=""
                        src="/linitani-white.png"
                        className="h-8 w-auto"
                    />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                    type="button"
                    onClick={() => setMobileMenuOpen(true)}
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon aria-hidden="true" className="size-6" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="text-sm/6 font-semibold text-white">
                        {item.name}
                    </a>
                    ))}
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <a href="#" className="text-sm/6 font-semibold text-white">
                    Contact Us <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
                </nav>
                <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
                <div className="fixed inset-0 z-50" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Your Company</span>
                        <img
                        alt=""
                        src="/linitani.png"
                        className="h-8 w-auto"
                        />
                    </a>
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(false)}
                        className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon aria-hidden="true" className="size-6" />
                    </button>
                    </div>
                    <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-2 py-6">
                        {navigation.map((item) => (
                            <a
                            key={item.name}
                            href={item.href}
                            className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                            >
                            {item.name}
                            </a>
                        ))}
                        </div>
                        <div className="py-6">
                        {/* <a
                            href="/auth"
                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                        >
                            Log in
                        </a> */}
                        </div>
                    </div>
                    </div>
                </DialogPanel>
                </Dialog>
            </header>

            <main className="isolate">
                {/* Hero section */}
                <div className="relative pt-14">
                    <div
                        aria-hidden="true"
                        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-20"
                    >
                        {/* <div
                        style={{
                            clipPath:
                            'polygon(74.1% 44.11%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        /> */}
                        <div
                        style={{
                            background: 'linear-gradient(180deg, #10245C 0%, #2B8445 100%)',
                            opacity: '0.5',
                            filter: 'blur(158px)', // Adjust blur value as needed
                            
                        }}
                        className="left-[50vw] relative aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] sm:w-[60.1875rem]"
                        />
                    </div>
                    <div className="pt-24 sm:pt-32">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto text-center">
                            <div className="text-balance text-md mb-4 font-bold tracking-tight text-[#36A556] sm:text-2xl">
                                Revolutionizing Agriculture
                            </div>
                            <h1 className="text-balance text-2xl font-semibold tracking-tight text-white sm:text-6xl">
                                Enhance Sustainability & Traceability Agriculture with Blockchain Environment
                            </h1>
                            <p className="mt-4 sm:mt-8 text-pretty text-[10px] sm:text-sm font-medium text-white">
                                Witness the dawn of a new era where every seed sown is a promise, and every harvest tells a story—powered by blockchain.
                            </p>
                        </div>
                        <div className="mt-16 flow-root sm:mt-24 -z-5">
                            <div className="-m-2 rounded-xl flex justify-center items-center"> 
                                <img
                                alt="App screenshot"
                                src="/hero1.png"
                                width={400}
                                height={400}
                                className="rounded-md relative right-0 sm:right-[5vw]"
                                />
                                <img
                                alt="App screenshot"
                                src="/hero2.png"
                                width={300}
                                height={280}
                                className="hidden md:block absolute -z-10 top-[50vw] right-[30vw] rounded-md shadow-2xl ring-1 ring-gray-900/10" 
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                
                </div>

                {/* Who we are*/}
                <div className="w-fulll bg-white mt-2 px-5 py-5 md:px-40 md:py-20">
                {/* <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                    <img
                    alt="Transistor"
                    src="https://tailwindui.com/plus/img/logos/158x48/transistor-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                    alt="Reform"
                    src="https://tailwindui.com/plus/img/logos/158x48/reform-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                    alt="Tuple"
                    src="https://tailwindui.com/plus/img/logos/158x48/tuple-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    />
                    <img
                    alt="SavvyCal"
                    src="https://tailwindui.com/plus/img/logos/158x48/savvycal-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                    />
                    <img
                    alt="Statamic"
                    src="https://tailwindui.com/plus/img/logos/158x48/statamic-logo-gray-900.svg"
                    width={158}
                    height={48}
                    className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    />
                </div> */}
                <div className="mt-8 sm:mt-16 flex-row sm:flex justify-center gap-x-0 sm:gap-x-10">
                    <div className='flex-1'>
                        <div className='w-full'>
                            <p className='text-xl font-bold text-[#36A556]'>Who We Are</p>
                            <p className='text-5xl font-semibold'>Empowering Smallholder farmers into the next level farmers</p>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className='w-full'>
                            <p className='mt-8 text-gray-600'>
                            We are a Co-integrated farming focused on building people and enhancing a conventional smallholder farmers into next level farmers through technology and end-to-end food processing.
                            </p>
                            <p className='mt-2 text-gray-600'>
                            Linitani is part of Sekolah Tani Indonesia
                            </p>
                            <img
                                className='mt-4'
                                src='/logo-hero-section2.png'
                                width={200}
                                height={200}
                            >

                            </img>
                        </div>
                    </div>
                    {/* <p className="relative rounded-full px-4 py-1.5 text-sm/6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
                    <span className="hidden md:inline">
                        Transistor saves up to $40,000 per year, per employee by working with us.
                    </span>
                    <a href="#" className="font-semibold text-indigo-600">
                        <span aria-hidden="true" className="absolute inset-0" /> Read our case study{' '}
                        <span aria-hidden="true">&rarr;</span>
                    </a>
                    </p> */}
                </div>
                <div className='flex w-full'>
                <img
                    alt=""
                    src="/image-section2-1.png"
                    width="80%"
                    height={150}
                    className="md:mt-20 mt-5"
                    />
                
                    <img
                    alt=""
                    src="/image-section2-2.png"
                    width="20%"
                    height={150}
                    className="md:mt-20 mt-5"
                    />
                </div>
                </div>

                {/* What we do */}
                <div className="bg-white px-5 py-10 md:px-40">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-xl/7 font-semibold text-xl text-[#36A556]">What We Do</h2>
                    <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
                        Building People First, Then Combining with Technology
                    </p>
                    <p className="mt-6 text-pretty text-lg/8 text-gray-600">
                        Building people is our number 1 prioritize because mostly smallholder farmers have a limited literacy and accessibility with the technology
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none">
                        {contents.map((content, index) => (
                            <div key={index} className="relative flex flex-col lg:flex-row my-10">
                                {/* Image on the left if reverse is false, otherwise on the right */}
                                {!content.reverse && (
                                    <div className="lg:w-1/2 pr-4">
                                        <img
                                            alt={content.title}
                                            src={content.img}
                                            width="100%"
                                            className="rounded-md"
                                        />
                                    </div>
                                )}

                                <div className="lg:w-1/2 lg:pl-4 flex items-center">
                                    <div className="flex flex-col px-4"> {/* Added flex-col here */}
                                        <dt className="text-base font-semibold text-gray-900 py-4">
                                            <div className="mb-2 rounded-lg text-[#36A556] inline-block">
                                                {content.tags}
                                            </div>
                                            <div className='text-4xl'>{content.title}</div>
                                        </dt>
                                        <dd className="mt-2 text-base text-gray-600">{content.description}</dd>
                                    </div>
                                </div>

                                {/* Image on the right if reverse is true */}
                                {content.reverse && (
                                    <div className="lg:w-1/2 pl-4">
                                        <img
                                            alt={content.title}
                                            src={content.img}
                                            width="100%"
                                            className="rounded-md"
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </dl>
                    
                </div>
                </div>

                {/* Our Impact */}
                <div className="bg-white py-12 sm:py-16">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
                        <div className="mx-auto max-w-2xl lg:max-w-none">
                        <h2 className="text-xl/7 font-semibold text-xl text-[#36A556]">Our Impact</h2>
                        <div className="text-center px-5 md:px-40 py-4">
                            <h2 className="text-balance text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                            Spread Happiness from Farm to Everyone
                            </h2>
                            <p className="mt-4 text-lg/8 text-gray-600 px-8">
                                For 3+ years we build from zero to hero, from unknown to be famous place. We dedicated our work to build a new hope for local farmers, environment and earth with healthiest and modernize farming method.
                            </p>
                        </div>
                            <dl className="mt-12 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-3 mx-2 md:mx-32">
                                {stats.map((stat, index) => (
                                    <div
                                        key={stat.id}
                                        className={`flex flex-col bg-white p-4 ${
                                            index < stats.length - 1 ? 'border-r border-gray-100 md:border-gray-400' : ''
                                        }`}
                                    >
                                        <dt className="text-sm/6 font-semibold text-gray-500">
                                            {stat.name}
                                        </dt>
                                        <dd className="text-3xl font-semibold tracking-tight text-gray-900">
                                            {stat.value}
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                        
                        <div className="container mx-auto sm:px-6 lg:px-8 flex gap-10 justify-center mt-10">
                            <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 bg-[#E6F8EF]">
                                <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-green-500">
                                <circle r={3} cx={3} cy={3} />
                                </svg>
                                Number of stability income increase up to 30%
                            </span>
                            <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 bg-[#F5F7F9]">
                                <svg viewBox="0 0 6 6" aria-hidden="true" className="size-1.5 fill-green-500">
                                <circle r={3} cx={3} cy={3} />
                                </svg>
                                Number of yields productions increase up to 50%
                            </span>
                        </div>

                        <div className="mt-16 flow-root sm:mt-24">
                            <div className="m-2 rounded-xl lg:-m-4 lg:rounded-2xl lg:p-4">
                            <img
                                alt="Last section"
                                src="/last-section.png"
                                width={2432}
                                height={1442}
                                className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* Footer */}
            <footer className="relative mx-auto px-6 lg:px-8 bg-white">
                <div className="border-t border-gray-900/10 py-4 sm:py-6 lg:py-8">
                <div className="flex-row md:flex justify-between">
                    <img
                        alt="Company name"
                        src="/linitani-big.png"
                        className="h-6 mb-2 sm:mb-0"
                    />
                    <p>
                        Copyright©2024. Linitani Indonesia
                    </p>
                </div>
                </div>
            </footer>
        </div>
    </Fragment>
    
  )
}
