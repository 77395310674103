import { useState } from 'react';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

export default function Form({onClose}) {
    const [projectId, setProjectId] = useState('');
    const [selectedLocation, setSelectedLocation] = useState(''); // State to store the selected location
    const locations = ['Sarangan #1, Magetan', 'Plaosan, Magetan', 'Sarangan #2, Magetan', 'Kebonsari, Madiun']; // Static location data

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const generateProjectId = () => {
        let id = '';
        for (let i = 0; i < 10; i++) {
            id += Math.floor(Math.random() * 10);
        }
        setProjectId(id);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Form submitted');

        const productName = event.target.projectname.value;
        const farmId = projectId; // Assuming projectId is the farmId
        const location = selectedLocation;
        const description = event.target.about.value;

        try {
            // const response = await fetch('http://localhost:3005/api/products', {
            const response = await fetch('https://linitani-backend-dev-5ecf7c5e0bda.herokuapp.com/api/products', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    productName,
                    farmId,
                    location,
                    description
                })
            });

            if (response.ok) {
                // Handle successful submission (e.g., show a success message, close the form)
                console.log('Product created successfully!');
                onClose(false); // Close the form
            } else {
                // Handle error (e.g., display an error message)
                console.error('Failed to create product');
            }
        } catch (error) {
            console.error("Error saving product:", error);
            alert("Failed to save product. Please try again later.");
        }
    }

    return (
        <form onSubmit={handleSubmit} className='p-2 md:p-10'>
            <div className="space-y-12 sm:space-y-16">
                <div>
                <h2 className="text-base/7 font-semibold text-gray-900">Project</h2>
                <p className="mt-1 max-w-2xl text-sm/6 text-gray-600">
                    This information will be displayed publicly so be careful what you share.
                </p>

                <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <label htmlFor="projectname" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Project commodity
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex items-center rounded-md bg-white pl-3 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-[#2b8445] sm:max-w-md">
                        <div className="shrink-0 select-none text-base text-gray-500 sm:text-sm/6">linitani.com/</div>
                        <input
                            id="projectname"
                            name="projectname"
                            type="text"
                            placeholder="janesmith"
                            className="block min-w-0 grow py-1.5 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                        />
                        </div>
                    </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                        <label htmlFor="projectname" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                            Project location
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <select
                                id="location"
                                name="location"
                                value={selectedLocation}
                                onChange={handleLocationChange}
                                className="block w-full rounded-md border-gray-300 py-1.5 pl-3 pr-10 text-base text-gray-900 focus:border-[#2b8445] focus:outline-none focus:ring-[#2b8445] sm:text-sm/6"
                            >
                                <option value="">Select a location</option>
                                {locations.map((location) => (
                                    <option key={location} value={location}>
                                        {location}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                        <label htmlFor="projectname" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                            Project ID
                        </label>
                        <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex items-center rounded-md bg-white pl-3 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-[#2b8445] sm:max-w-md">
                                <input
                                    id="batch"
                                    name="batch"
                                    type="text"
                                    value={projectId}
                                    readOnly
                                    placeholder="ID Project"
                                    className="block min-w-0 grow py-1.5 pl-1 pr-3 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
                                />
                                <button type="button" onClick={generateProjectId} className="shrink-0 px-3 py-1.5 text-sm font-medium text-gray-900 bg-gray-100 hover:bg-gray-200 rounded-r-md">
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <label htmlFor="about" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        About
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-[#2b8445] sm:max-w-2xl sm:text-sm/6"
                        defaultValue={''}
                        />
                        <p className="mt-3 text-sm/6 text-gray-600">Write a few sentences about the project.</p>
                    </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                    <label htmlFor="photo" className="block text-sm/6 font-medium text-gray-900">
                        Photo (optional)
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex items-center gap-x-3">
                        <UserCircleIcon aria-hidden="true" className="size-12 text-gray-300" />
                        <button
                            type="button"
                            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            Change
                        </button>
                        </div>
                    </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                    <label htmlFor="cover-photo" className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5">
                        Project photo (optional)
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex max-w-2xl justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                            <PhotoIcon aria-hidden="true" className="mx-auto size-12 text-gray-300" />
                            <div className="mt-4 flex text-sm/6 text-gray-600">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-semibold text-[#2b8445] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#2b8445] focus-within:ring-offset-2 hover:text-[#9adeae]"
                            >
                                <span>Upload a file</span>
                                <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs/5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-center md:justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900" onClick={() => onClose(false)}>
                Cancel
                </button>
                <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-[#2b8445] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#9adeae] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2b8445]"
                >
                Save
                </button>
            </div>
        </form>
    )
}
